.custom-table thead tr th{
    padding: 10px;
    background: whitesmoke;
}
.custom-table thead tr th:nth-child(1){
    border-bottom-left-radius: 10px;
}
.custom-table thead tr th:nth-child(1){
    border-top-left-radius: 10px;
}
.custom-table thead tr th:nth-child(5){
    border-bottom-right-radius: 10px;
}
.custom-table thead tr th:nth-child(5){
    border-top-right-radius: 10px;
}
.custom-table tbody tr td{
    padding: 10px;
}
.font-0{
    font-size: 40px;
}
.font-1{
    font-size: 50px;
}
.font-2{
    font-size: 30px;
}
.thumbs-wrapper.axis-vertical{
    display: none;
}
.iconic-image{
    width: 50px;
}
.teal{
    background: #1ce1c3 !important;
}
.indigo{
    background: #8149ed !important
}
.warning{
    background: #ffbf18 !important;
}
.blue{
    background: #4db1fa !important
}
.orange{
    background: #ff923e !important;
}
.pink{
    background: #ff72b0 !important;
}
.violet{
    background: #c91ae7;
}
.cyan{
    background: #12d7a2 !important;
}
@media only screen and (max-width: 1366px) {
    .font-0{
        font-size: 30px;
    }
    .font-1{
        font-size: 40px;
    }
    .font-2{
        font-size: 20px;
    }
    .iconic-image{
        width: 30px;
    }
}